import { Link } from '@remix-run/react';
import { SignOutButton, useUser } from '@clerk/remix';
import '../styles/navbar.css';

import type { CatWithUnreadCount } from '~/types/CatWithUnreadCount';
import { FaBookReader, FaHome } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { Fade as Hamburger } from 'hamburger-react';
import { useState } from 'react';

//import { User } from "@clerk/remix/api.server";

export default function Navbar({
  cats,
}: {
  cats: CatWithUnreadCount[] | null;
}) {
  const { isLoaded, isSignedIn } = useUser();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className='header'>
        <nav id='menu' className='menu'>
          <a className='logo' href='/'>
            ACCESSIBLE RSS
          </a>
          <div className={isOpen ? 'wrapper_active' : 'wrapper'}>
            <button className='toggle' onClick={handleToggle}>
              <Hamburger
                color='#fff'
                size={25}
                onToggle={handleToggle}
                toggled={isOpen}
              />
            </button>
            <div
              className={isOpen ? 'menu_list_inner_active' : 'menu_list_inner'}
            >
              <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                <Link className='menu_link' to='/'>
                  Home
                </Link>
              </div>
              {!isLoaded || !isSignedIn ? (
                <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                  <Link className='menu_link' to='/signin'>
                    Log In
                  </Link>
                </div>
              ) : (
                <>
                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/pocket'>
                      Connect to Pocket
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/instapaper'>
                      Instapaper Credentials
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <div className='signout'>
                      <SignOutButton />
                    </div>
                  </div>
                </>
              )}
              <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                <div className='menu_link_d'>
                  <a
                    className='donate'
                    href='https://www.paypal.com/donate/?hosted_button_id=HE7TW355VHB5L'
                  >
                    Donate with Paypal
                  </a>
                </div>
              </div>
              {isSignedIn && isLoaded && (
                <>
                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/feeds/viewunread'>
                      View Unread Items
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/feeds/viewall'>
                      View All Items
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/feeds/addfeed'>
                      Add Feed
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/feeds/manage'>
                      Manage Categories
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/generateopml'>
                      Export to OPML
                    </Link>
                  </div>

                  <div onClick={() => setIsOpen(!isOpen)} className='menu_item'>
                    <Link className='menu_link' to='/uploadopml'>
                      Import OPML File
                    </Link>
                  </div>
                </>
              )}
              {isSignedIn && isLoaded && (
                <>
                  {cats?.map((f) => {
                    return (
                      <div
                        key={f.id}
                        onClick={() => setIsOpen(!isOpen)}
                        className='menu_item'
                      >
                        <Link
                          className='menu_link'
                          to={`/cats/view/${f.id}/unread/1`}
                        >
                          {f.name}({f.unreadCount})
                        </Link>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
      {/* <div className="w-full flex bg-sky-600 drop-shadow-md px-4 py-3 justify-center items-center text-white gap-x-3">
      <Link className='menu_link' to="/">Home</Link>
      {!user ? (
        <>
        <Link className='menu_link' to="/login">Log In</Link>
        </>
        ) : (
          <>
          <Link className='menu_link' to="/pocket">Connect to Pocket</Link>
          <Link className='menu_link' to="/instapaper">Instapaper Credentials</Link>
            <Form method="post" action="/logout">
              <button type="submit">Log Out</button>
            </Form>
          </>
        )}
      </div>
      <div className="w-full flex drop-shadow-md px-4 py-3 justify-center items-center gap-x-3">
        {user && (
          <>
            <Link className='menu_link' to="/feeds/view">View Unread Items</Link>
            <Link className='menu_link' to="/feeds/viewall">View All Items</Link>
            <Link className='menu_link' to="/feeds/addfeed">Add Feed</Link>
            <Link className='menu_link' to="/feeds/manage">Manage Categories</Link>
          </>
        )}
      </div>
      <div className="w-full flex bg-sky-600 drop-shadow-md px-4 py-3 justify-center items-center text-white gap-x-3">
        {user && (
          <>
            {cats?.map((f) => {
              return (
                <Link className='menu_link' key={f.id} to={`/cats/view/${f.id}/unread`}>
                  {f.name}({f.unreadCount})
                </Link>
              );
            })}
          </>
        )}
      </div> */}
    </>
  );
}

import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { prisma } from './db.server';
import styles from '~/styles/app.css?url';
import { json } from '@remix-run/node';

import type { LinksFunction, LoaderFunction } from '@remix-run/node';
import type { CatWithUnreadCount } from './types/CatWithUnreadCount';
import {
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  Links,
} from '@remix-run/react';
import { getAuth, rootAuthLoader } from '@clerk/remix/ssr.server';
import { ClerkApp } from '@clerk/remix';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

type LoaderData = {
  user: string | null;
  cats: CatWithUnreadCount[] | null;
  env: object;
};
export const loader: LoaderFunction = (args) =>
  rootAuthLoader(args, async (args) => {
    let cats: CatWithUnreadCount[] = [];

    const { userId } = await getAuth(args);
    if (userId) {
      cats = await prisma.$queryRaw<
        CatWithUnreadCount[]
      >`select c.id as id, c.name as name, coalesce(sum(unreadCount),0) as "unreadCount" from "Category" c left outer join (select c.id, count(distinct i.id) as unreadCount from "Category" c inner join "Subscribe" s on s."categoryId"=c.id  and s."userId"=${userId} and s."userId"=c."userId" inner join "Item" i on i."feedId"=s."feedId" left outer join "ReadItem" r on r."itemId"=i.id and r."userId"=s."userId" where r."itemId" is null or r.read=false group by c.id)as counts on counts.id=c.id  where c."userId"=${userId} group by c.id, c.name order by c.name asc`;
    }
    const env: any = {};
    env['BASE_URL'] = process.env.BASE_URL;
    return json<LoaderData>({ user: userId, cats, env });
  });
export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

function App() {
  const { cats, env } = useLoaderData<LoaderData>();

  return (
    <html lang='en'>
      <head>
        <title>rss-reader</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <div>
          <Navbar cats={cats} />

          <main>
            <Outlet />
            <ScrollRestoration />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(env)}`,
              }}
            />
            <Scripts />
          </main>
          <Footer />
        </div>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default ClerkApp(App);
